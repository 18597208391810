import React from "react"
import { Flex, Heading, Text, Box } from "@chakra-ui/react"
import { ChevronDownIcon } from "@chakra-ui/icons"
import { merge } from "lodash"

const defaultStyles = {
  root: {
    bg: { base: "#E8EBFE", md: "#E8EBFE", lg: "transparent" },
    flexDirection: { base: "column-reverse", md: "column-reverse", lg: "row" },
    alignItems: "center",
    pt: { base: "40px", md: "40px", lg: "80px" },
    width: "100%",
    px: { base: "0px", md: "0px", lg: "10%" },
    justifyContent: "space-between",
  },
  "left-box": {
    px: { base: "24px", md: "24px", lg: "0px" },
    flexDirection: "column",
    justifyContent: "center",
    alignItems: { base: "center", md: "center", lg: "flex-start" },
  },
  heading: {
    fontWeight: "bold",
    fontSize: { base: "24px", md: "24px", lg: "48px" },
    lineHeight: { base: "32px", md: "32px", lg: "56px" },
    color: "#1B263F",
  },
  text: {
    mt: { base: "16px", md: "16px", lg: "24px" },
    textAlign: { base: "center", md: "center", lg: "left" },
    fontSize: "16px",
    lineHeight: "150%",
    letterSpacing: "0.0015em",
    color: "#1B263F",
  },
  image: {
    height: { base: "auto", md: "auto", lg: "407px" },
    width: { base: "100%", md: "100%", lg: "658px" },
  },
  icon: {
    display: { base: "flex", md: "flex", lg: "none" },
    color: "#9CACBC",
    fontSize: "42px",
    mt: "32px",
  },
}

const Hero = ({ Image, title, content, styles }) => {
  const _styles = merge({}, defaultStyles, styles)
  return (
    <Flex sx={_styles.root}>
      <ChevronDownIcon sx={_styles.icon} />
      <Flex sx={_styles["left-box"]}>
        <Heading sx={_styles.heading}>{title}</Heading>
        <Text sx={_styles.text}>{content}</Text>
      </Flex>
      {Image && (
        <Box sx={_styles.image}>
          <Image />
        </Box>
      )}
    </Flex>
  )
}

Hero.defaultProps = {
  styles: {},
}

export default Hero
