import React from "react"
import { Link as GatsbyLink } from "gatsby"
import { Text, Flex, Link } from "@chakra-ui/react"
import Hero from "../components/hero"

import Layout from "../components/layout"

const styles = {
  hero: {
    root: {
      pt: "40px",
      pb: "20px",
    },
    "left-box": {
      width: "100%",
      alignItems: { base: "center", md: "center", lg: "center" },
    },
    heading: {
      textAlign: "center",
    },
    icon: {
      mt: "0px",
    },
  },
  "text-box": {
    flexDirection: "column",
    width: "100%",
    px: "24px",
    pb: "70px",
  },
  text: {
    mt: "24px",
    textAlign: "left",
    fontSize: "16px",
    lineHeight: "150%",
    letterSpacing: "0.0015em",
    color: "#1B263F",
  },
}

class PrivacyPolicy extends React.Component {
  render() {
    return (
      <Layout location={this.props.location} title={"Privacy Policy"}>
        <Hero title="Privacy Policy" styles={styles.hero} />
        <Flex sx={styles["text-box"]}>
          <Text sx={styles.text} fontWeight="bold">
            Avant-garde Health Website Privacy Policy
          </Text>

          <Text sx={styles.text}>
            We respect your privacy and are committed to protecting it through
            our compliance with this privacy policy (&ldquo;Policy&rdquo;). This
            Policy describes the types of information we may collect from you or
            that you may provide (&ldquo;Personal Information&rdquo;) on the
            avantgardehealth.com website (&ldquo;Website&rdquo; or
            &ldquo;Service&rdquo;) and any of its related products and services
            (collectively, &ldquo;Services&rdquo;), and our practices for
            collecting, using, maintaining, protecting, and disclosing that
            Personal Information. It also describes the choices available to you
            regarding our use of your Personal Information and how you can
            access and update it.
          </Text>

          <Text sx={styles.text}>
            This Policy is a legally binding agreement between you
            (&ldquo;User&rdquo;, &ldquo;you&rdquo; or &ldquo;your&rdquo;) and
            Avant-garde Health (&ldquo;Avant-garde Health&rdquo;,
            &ldquo;we&rdquo;, &ldquo;us&rdquo; or &ldquo;our&rdquo;). If you are
            entering into this agreement on behalf of a business or other legal
            entity, you represent that you have the authority to bind such
            entity to this agreement, in which case the terms
            &ldquo;User&rdquo;, &ldquo;you&rdquo; or &ldquo;your&rdquo; shall
            refer to such entity. If you do not have such authority, or if you
            do not agree with the terms of this agreement, you must not accept
            this agreement and may not access and use the Website and Services.
            By accessing and using the Website and Services, you acknowledge
            that you have read, understood, and agree to be bound by the terms
            of this Policy. This Policy does not apply to the practices of
            companies that we do not own or control, or to individuals that we
            do not employ or manage.
          </Text>

          <Text sx={styles.text} fontWeight="bold">
            Automatic collection of information
          </Text>

          <Text sx={styles.text}>
            When you open the Website, our servers automatically record
            information that your browser sends. This data may include
            information such as your device&rsquo;s IP address, browser type,
            and version, operating system type and version, language preferences
            or the webpage you were visiting before you came to the Website and
            Services, pages of the Website and Services that you visit, the time
            spent on those pages, information you search for on the Website,
            access times and dates, and other statistics.
          </Text>

          <Text sx={styles.text}>
            Information collected automatically is used only to identify
            potential cases of abuse and establish statistical information
            regarding the usage and traffic of the Website and Services. This
            statistical information is not otherwise aggregated in such a way
            that would identify any particular User of the system.
          </Text>

          <Text sx={styles.text} fontWeight="bold">
            Collection of personal information
          </Text>

          <Text sx={styles.text}>
            You can access and use the Website and Services without telling us
            who you are or revealing any information by which someone could
            identify you as a specific, identifiable individual. If, however,
            you wish to use some of the features offered on the Website, you may
            be asked to provide certain Personal Information (for example, your
            name and e-mail address).
          </Text>

          <Text sx={styles.text}>
            We receive and store any information you knowingly provide to us
            when you fill any forms on the Website. When required, this
            information may include the following:
          </Text>

          <Text sx={styles.text}>
            - Contact information (such as email address, phone number, etc)
          </Text>
          <Text sx={styles.text}>
            - Basic personal information (such as name, country of residence,
            etc)
          </Text>

          <Text sx={styles.text}>
            You can choose not to provide us with your Personal Information, but
            then you may not be able to take advantage of some of the features
            on the Website. Users who are uncertain about what information is
            mandatory are welcome to contact us.
          </Text>

          <Text sx={styles.text} fontWeight="bold">
            Privacy of children
          </Text>

          <Text sx={styles.text}>
            We do not knowingly collect any Personal Information from children
            under the age of 18. If you are under the age of 18, please do not
            submit any Personal Information through the Website and Services. If
            you have reason to believe that a child under the age of 18 has
            provided Personal Information to us through the Website and
            Services, please contact us to request that we delete that
            child&rsquo;s Personal Information from our Services.
          </Text>

          <Text sx={styles.text}>
            We encourage parents and legal guardians to monitor their
            children&rsquo;s Internet usage and to help enforce this Policy by
            instructing their children never to provide Personal Information
            through the Website and Services without their permission. We also
            ask that all parents and legal guardians overseeing the care of
            children take the necessary precautions to ensure that their
            children are instructed to never give out Personal Information when
            online without their permission.
          </Text>

          <Text sx={styles.text} fontWeight="bold">
            Use and processing of collected information
          </Text>

          <Text sx={styles.text}>
            We act as a data controller and a data processor when handling
            Personal Information, unless we have entered into a data processing
            agreement with you in which case you would be the data controller
            and we would be the data processor.
          </Text>

          <Text sx={styles.text}>
            Our role may also differ depending on the specific situation
            involving Personal Information. We act in the capacity of a data
            controller when we ask you to submit your Personal Information that
            is necessary to ensure your access and use of the Website and
            Services. In such instances, we are a data controller because we
            determine the purposes and means of the processing of Personal
            Information.
          </Text>

          <Text sx={styles.text}>
            We act in the capacity of a data processor in situations when you
            submit Personal Information through the Website and Services. We do
            not own, control, or make decisions about the submitted Personal
            Information, and such Personal Information is processed only in
            accordance with your instructions. In such instances, the User
            providing Personal Information acts as a data controller.
          </Text>

          <Text sx={styles.text}>
            In order to make the Website and Services available to you, or to
            meet a legal obligation, we may need to collect and use certain
            Personal Information. If you do not provide the information that we
            request, we may not be able to provide you with the requested
            products or services. Any of the information we collect from you may
            be used for the following purposes:
          </Text>

          <Text sx={styles.text}>- Improve products and services</Text>
          <Text sx={styles.text}>
            - Send marketing and promotional communications
          </Text>
          <Text sx={styles.text}>- Send product and service updates</Text>
          <Text sx={styles.text}>- Respond to inquiries and offer support</Text>
          <Text sx={styles.text}>- Improve user experience</Text>
          <Text sx={styles.text}>- Deliver targeted advertising</Text>
          <Text sx={styles.text}>
            - Enforce terms and conditions and policies
          </Text>
          <Text sx={styles.text}>- Protect from abuse and malicious users</Text>
          <Text sx={styles.text}>
            - Respond to legal requests and prevent harm
          </Text>
          <Text sx={styles.text}>
            - Run and operate the Website and Services
          </Text>

          <Text sx={styles.text}>
            Processing your Personal Information depends on how you interact
            with the Website and Services, where you are located in the world
            and if one of the following applies: (i) you have given your consent
            for one or more specific purposes; this, however, does not apply,
            whenever the processing of Personal Information is subject to
            California Consumer Privacy Act; (ii) provision of information is
            necessary for the performance of an agreement with you and/or for
            any pre-contractual obligations thereof; (iii) processing is
            necessary for compliance with a legal obligation to which you are
            subject; (iv) processing is related to a task that is carried out in
            the public interest or in the exercise of official authority vested
            in us; (v) processing is necessary for the purposes of the
            legitimate interests pursued by us or by a third party.
          </Text>

          <Text sx={styles.text}>
            Note that under some legislations we may be allowed to process
            information until you object to such processing by opting out,
            without having to rely on consent or any other of the legal bases.
            In any case, we will be happy to clarify the specific legal basis
            that applies to the processing, and in particular whether the
            provision of Personal Information is a statutory or contractual
            requirement, or a requirement necessary to enter into a contract.
          </Text>

          <Text sx={styles.text} fontWeight="bold">
            Disclosure of information
          </Text>

          <Text sx={styles.text}>
            Depending on the requested Services or as necessary to complete any
            transaction or provide any Service you have requested, we may share
            your information with our affiliates, contracted companies, and
            service providers (collectively, &ldquo;Service Providers&rdquo;) we
            rely upon to assist in the operation of the Website and Services
            available to you and whose privacy policies are consistent with ours
            or who agree to abide by our policies with respect to Personal
            Information. We will not share any personally identifiable
            information with third parties and will not share any information
            with unaffiliated third parties.
          </Text>

          <Text sx={styles.text}>
            Service Providers are not authorized to use or disclose your
            information except as necessary to perform services on our behalf or
            comply with legal requirements. Service Providers are given the
            information they need only in order to perform their designated
            functions, and we do not authorize them to use or disclose any of
            the provided information for their own marketing or other purposes.
          </Text>

          <Text sx={styles.text}>
            We may also disclose any Personal Information we collect, use or
            receive if required or permitted by law, such as to comply with a
            subpoena or similar legal process, and when we believe in good faith
            that disclosure is necessary to protect our rights, protect your
            safety or the safety of others, investigate fraud, or respond to a
            government request.
          </Text>

          <Text sx={styles.text} fontWeight="bold">
            Retention of information
          </Text>

          <Text sx={styles.text}>
            We will retain and use your Personal Information for the period
            necessary to comply with our legal obligations, to enforce our
            agreements, resolve disputes, and unless a longer retention period
            is required or permitted by law.
          </Text>

          <Text sx={styles.text}>
            We may use any aggregated data derived from or incorporating your
            Personal Information after you update or delete it, but not in a
            manner that would identify you personally. Once the retention period
            expires, Personal Information shall be deleted. Therefore, the right
            to access, the right to erasure, the right to rectification, and the
            right to data portability cannot be enforced after the expiration of
            the retention period.
          </Text>

          <Text sx={styles.text} fontWeight="bold">
            California privacy rights
          </Text>

          <Text sx={styles.text}>
            Consumers residing in California are afforded certain additional
            rights with respect to their Personal Information under the
            California Consumer Privacy Act (&ldquo;CCPA&rdquo;). If you are a
            California resident, this section applies to you.
          </Text>

          <Text sx={styles.text}>
            In addition to the rights as explained in this Policy, California
            residents who provide Personal Information as defined in the statute
            to obtain Services for personal, family, or household use are
            entitled to request and obtain from us, once a calendar year,
            information about the categories and specific pieces of Personal
            Information we have collected and disclosed.
          </Text>

          <Text sx={styles.text}>
            Furthermore, California residents have the right to request deletion
            of their Personal Information or opt-out of the sale of their
            Personal Information which may include selling, disclosing, or
            transferring Personal Information to another business or a third
            party for monetary or other valuable consideration. To do so, simply
            contact us. We will not discriminate against you if you exercise
            your rights under the CCPA.
          </Text>

          <Text sx={styles.text} fontWeight="bold">
            How to exercise your rights
          </Text>

          <Text sx={styles.text}>
            Any requests to exercise your rights can be directed to us through
            the contact details provided in this document. Please note that we
            may ask you to verify your identity before responding to such
            requests. Your request must provide sufficient information that
            allows us to verify that you are the person you are claiming to be
            or that you are the authorized representative of such person. If we
            receive your request from an authorized representative, we may
            request evidence that you have provided such an authorized
            representative with power of attorney or that the authorized
            representative otherwise has valid written authority to submit
            requests on your behalf.
          </Text>

          <Text sx={styles.text}>
            You must include sufficient details to allow us to properly
            understand the request and respond to it. We cannot respond to your
            request or provide you with Personal Information unless we first
            verify your identity or authority to make such a request and confirm
            that the Personal Information relates to you.
          </Text>

          <Text sx={styles.text} fontWeight="bold">
            Cookies
          </Text>

          <Text sx={styles.text}>
            Our Website and Services use &ldquo;cookies&rdquo; to help
            personalize your online experience. A cookie is a text file that is
            placed on your hard disk by a web page server. Cookies cannot be
            used to run programs or deliver viruses to your computer. Cookies
            are uniquely assigned to you, and can only be read by a web server
            in the domain that issued the cookie to you. You may learn more
            about cookies and how they work in this guide.
          </Text>

          <Text sx={styles.text}>
            We may use cookies to collect, store, and track information for
            security and personalization, and for statistical purposes. Please
            note that you have the ability to accept or decline cookies. Most
            web browsers automatically accept cookies by default, but you can
            modify your browser settings to decline cookies if you prefer.
          </Text>

          <Text sx={styles.text} fontWeight="bold">
            Data analytics
          </Text>

          <Text sx={styles.text}>
            Our Website and Services may use third-party analytics tools that
            use cookies, web beacons, or other similar information-gathering
            technologies to collect standard internet activity and usage
            information. The information gathered is used to compile statistical
            reports on User activity such as how often Users visit our Website
            and Services, what pages they visit and for how long, etc. We use
            the information obtained from these analytics tools to monitor the
            performance and improve our Website and Services. We do not use
            third-party analytics tools to track or to collect any personally
            identifiable information of our Users and we will not associate any
            information gathered from the statistical reports with any
            individual User.
          </Text>

          <Text sx={styles.text} fontWeight="bold">
            Do Not Track signals
          </Text>

          <Text sx={styles.text}>
            Some browsers incorporate a Do Not Track feature that signals to
            websites you visit that you do not want to have your online activity
            tracked. Tracking is not the same as using or collecting information
            in connection with a website. For these purposes, tracking refers to
            collecting personally identifiable information from consumers who
            use or visit a website or online service as they move across
            different websites over time. The Website and Services do not track
            its visitors over time and across third-party websites. However,
            some third-party websites may keep track of your browsing activities
            when they serve you content, which enables them to tailor what they
            present to you.
          </Text>

          <Text sx={styles.text} fontWeight="bold">
            Advertisements
          </Text>

          <Text sx={styles.text}>
            We may permit certain third-party companies to help us tailor
            advertising that we think may be of interest to Users and to collect
            and use other data about User activities on the Website. These
            companies may deliver ads that might place cookies and otherwise
            track User behavior.
          </Text>

          <Text sx={styles.text} fontWeight="bold">
            Social media features
          </Text>

          <Text sx={styles.text}>
            Our Website and Services may include social media features, such as
            the Facebook and Twitter buttons, Share This buttons, etc
            (collectively, &ldquo;Social Media Features&rdquo;). These Social
            Media Features may collect your IP address, what page you are
            visiting on our Website and Services, and may set a cookie to enable
            Social Media Features to function properly. Social Media Features
            are hosted either by their respective providers or directly on our
            Website and Services. Your interactions with these Social Media
            Features are governed by the privacy policy of their respective
            providers.
          </Text>

          <Text sx={styles.text} fontWeight="bold">
            Email marketing
          </Text>

          <Text sx={styles.text}>
            We offer electronic newsletters to which you may voluntarily
            subscribe at any time. We are committed to keeping your e-mail
            address confidential and will not disclose your email address to any
            third parties except as allowed in the information use and
            processing section or for the purposes of utilizing a third-party
            provider to send such emails. We will maintain the information sent
            via e-mail in accordance with applicable laws and regulations.
          </Text>

          <Text sx={styles.text}>
            In compliance with the CAN-SPAM Act, all e-mails sent from us will
            clearly state who the e-mail is from and provide clear information
            on how to contact the sender. You may choose to stop receiving our
            newsletter or marketing emails by following the unsubscribe
            instructions included in these emails or by contacting us.
          </Text>

          <Text sx={styles.text} fontWeight="bold">
            Links to other resources
          </Text>

          <Text sx={styles.text}>
            The Website and Services contain links to other resources that are
            not owned or controlled by us. Please be aware that we are not
            responsible for the privacy practices of such other resources or
            third parties. We encourage you to be aware when you leave the
            Website and Services and to read the privacy statements of each and
            every resource that may collect Personal Information.
          </Text>

          <Text sx={styles.text} fontWeight="bold">
            Information security
          </Text>

          <Text sx={styles.text}>
            We secure information you provide on computer servers in a
            controlled, secure environment, protected from unauthorized access,
            use, or disclosure. We maintain reasonable administrative,
            technical, and physical safeguards in an effort to protect against
            unauthorized access, use, modification, and disclosure of Personal
            Information in our control and custody. However, no data
            transmission over the Internet or wireless network can be
            guaranteed.
          </Text>

          <Text sx={styles.text}>
            Therefore, while we strive to protect your Personal Information, you
            acknowledge that (i) there are security and privacy limitations of
            the Internet which are beyond our control; (ii) the security,
            integrity, and privacy of any and all information and data exchanged
            between you and the Website and Services cannot be guaranteed; and
            (iii) any such information and data may be viewed or tampered with
            in transit by a third party, despite best efforts.
          </Text>

          <Text sx={styles.text} fontWeight="bold">
            Data breach
          </Text>

          <Text sx={styles.text}>
            In the event we become aware that the security of the Website and
            Services has been compromised or Users&rsquo; Personal Information
            has been disclosed to unrelated third parties as a result of
            external activity, including, but not limited to, security attacks
            or fraud, we reserve the right to take reasonably appropriate
            measures, including, but not limited to, investigation and
            reporting, as well as notification to and cooperation with law
            enforcement authorities. In the event of a data breach, we will make
            reasonable efforts to notify affected individuals if we believe that
            there is a reasonable risk of harm to the User as a result of the
            breach or if notice is otherwise required by law. When we do, we
            will send you an email.
          </Text>

          <Text sx={styles.text} fontWeight="bold">
            Changes and amendments
          </Text>

          <Text sx={styles.text}>
            We reserve the right to modify this Policy or its terms related to
            the Website and Services at any time at our discretion. When we do,
            we will revise the updated date at the bottom of this page. We may
            also provide notice to you in other ways at our discretion, such as
            through the contact information you have provided.
          </Text>

          <Text sx={styles.text}>
            An updated version of this Policy will be effective immediately upon
            the posting of the revised Policy unless otherwise specified. Your
            continued use of the Website and Services after the effective date
            of the revised Policy (or such other act specified at that time)
            will constitute your consent to those changes. However, we will not,
            without your consent, use your Personal Information in a manner
            materially different than what was stated at the time your Personal
            Information was collected.
          </Text>

          <Text sx={styles.text}>Acceptance of this policy</Text>

          <Text sx={styles.text}>
            You acknowledge that you have read this Policy and agree to all its
            terms and conditions. By accessing and using the Website and
            Services and submitting your information you agree to be bound by
            this Policy. If you do not agree to abide by the terms of this
            Policy, you are not authorized to access or use the Website and
            Services.
          </Text>

          <Text sx={styles.text} fontWeight="bold">
            Contacting us
          </Text>

          <Text sx={styles.text}>
            If you have any questions, concerns, or complaints regarding this
            Policy, the information we hold about you, or if you wish to
            exercise your rights, we encourage you to contact us using the
            details below:
          </Text>

          <Link as={GatsbyLink} sx={styles.text} to="/contact-us">
            https://www.avantgardehealth.com/contact-us
          </Link>

          <Text sx={styles.text}>
            We will attempt to resolve complaints and disputes and make every
            reasonable effort to honor your wish to exercise your rights as
            quickly as possible and in any event, within the timescales provided
            by applicable data protection laws.
          </Text>

          <Text sx={styles.text}>
            This document was last updated on March 30, 2022
          </Text>
        </Flex>
      </Layout>
    )
  }
}

export default PrivacyPolicy
